import React, { useState, useEffect, useMemo } from 'react'
import Container from 'react-bootstrap/Container';

const ServiceList = props => {

  const {listItems} = props
  const [parsedItems, setParsedItems] = useState([]);

const getListItems = (listData) => {
    const results = []
    const jsoned = JSON.parse(JSON.stringify(listData)); 
    jsoned.content.map(levelOne => {
        levelOne.content.map(levelTwo => {
            if(levelTwo.content) {
                levelTwo.content.map(levelThree => {
                    if(levelThree.content) {
                         levelThree.content.map(levelFour => {
                            results.push(levelFour.value);
                        })
                        }
                })
           }
        })
    })
    setParsedItems(results);
}

useEffect(() => {
    getListItems(listItems);
},[])

return (
  <ul>
      <ul className="services-list">
          { 
          parsedItems && parsedItems.map((item, index) => {
              return(
                  <li key={index}>{item}</li>
              )
          })
          }
      </ul>
  </ul>
  )

}

export default ServiceList
