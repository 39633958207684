import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import CtaBar from '../components/cta-bar'
import Testimonials from '../components/testimonials'
import ServiceList from '../components/service-list'
import Clients from '../components/clients'
import Button from 'react-bootstrap/Button'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import Modal from 'react-bootstrap/Modal'

import * as contentful from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';




import services1 from '../assets/images/Health.png'
import services2 from '../assets/images/Technology.png'
import services3 from '../assets/images/Professional.png'

import SDMChart from '../assets/images/SDMChart.png'





class ServicesPage extends React.Component {
    constructor() {
        super();
        this.state = { show: false };
        this.toggleModal = this.toggleModal.bind(this);
    }
    toggleModal() {
        this.setState((state) => {
            return { show: !this.state.show }
        });
    }

    render() {

        const testimonials = get(this, 'props.data.allContentfulTestimonial.edges')
        const services = get(this, 'props.data.allContentfulService.edges')
        const location = this.props.location
        const jobLink = get(this, 'props.data.allContentfulJobListingLink.edges[0].node.link')
    
        return (
            <Layout location={this.props.location}>
                <div className="inner-page-services">
                    <Container>
                        <Breadcrumb location={location} crumbLabel="Services" />
                    </Container>
                    <Row className="inner-page-banner services-page-banner">
                        <Col>
                            <h2>SERVICES</h2>
                            <h1>How Can We Help You?</h1>
                            <a href={jobLink} target="_new"> <Button className="cta-button-banner">JOB LISTINGS</Button></a>
                        </Col>
                    </Row>
                    <h2 className="green-headline" style={{ textAlign: 'center', padding: '40px 0' }}>Your Trusted Partner</h2>
                </div>
                <Container>
                    {services ? services.map((service, index)=>{
                        return(
                            <Row key={index} className="space">
                            <Col md="2">
                                <img className="service-page-icon" src={service.node.icon.file.url} />
                            </Col>
                            <Col md="10" >
                                <h2 className="service-title">{service.node.title}</h2>
                                <p className="service-text">{service.node.body.body}</p>
                                <ServiceList listItems={JSON.parse(service.node.list.list)}/>
                            </Col>
                        </Row>
                        )
                    }) : <p>no services</p>}

                </Container>
                <Modal style={{ textAlign: 'center' }} id="sdm-chart-modal" show={this.state.show}>
                    <Modal.Body>
                    <a href="javascript:void(0)" style={{fontSize:'30px', position:'absolute',top:'10px', right:'10px', color: '#130C0D'}} onClick={this.toggleModal}>X</a>
                        <Row>
                            <Col className='my-auto'>
                                <h3 className="green-headline" style={{ textAlign: 'center' }}>“One Call” SDM CHART</h3>
                                <img style={{ maxWidth: '100%', padding:"5%", margin: 'auto', display: 'block' }} src={SDMChart} />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <CtaBar />
                <Testimonials testimonials={testimonials} />
                <Clients />
            </Layout>
        )
    }
}

export default ServicesPage

export const pageQuery = graphql`
  query ServicesQuery {
    allContentfulService(filter: {node_locale: {eq: "en-US"}}) {
        edges {
          node {
            body {
              body
            }
            icon {
              file {
                url
              }
            }
            title
            list {
              list
            }
          }
        }
      }
    allContentfulTestimonial(filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          name
          title
          quote
          image {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulJobListingLink {
      edges {
        node {
          link
        }
      }
    }
  }
`
